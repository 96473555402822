exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-annuler-js": () => import("./../../../src/pages/annuler.js" /* webpackChunkName: "component---src-pages-annuler-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bombe-bain-js": () => import("./../../../src/pages/bombe-bain.js" /* webpackChunkName: "component---src-pages-bombe-bain-js" */),
  "component---src-pages-cgv-js": () => import("./../../../src/pages/cgv.js" /* webpackChunkName: "component---src-pages-cgv-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-panier-js": () => import("./../../../src/pages/panier.js" /* webpackChunkName: "component---src-pages-panier-js" */),
  "component---src-pages-politique-cookies-js": () => import("./../../../src/pages/politique-cookies.js" /* webpackChunkName: "component---src-pages-politique-cookies-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

